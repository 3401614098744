a {
  text-decoration: none;
  color: inherit;
}

#nprogress {
  z-index: 1600;
  position: relative;
}

#nprogress div.spinner {
  display: none;
}
